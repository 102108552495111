import React, { useState, useEffect } from 'react';
import * as S from './styles';
import { Typography } from '~/components/@tem-ui';
import { DoctorSchedule } from '~/contexts/types';
import { parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface ScheduleTimesProps {
  loading: boolean;
  times: DoctorSchedule;
  onSelect: (item?: Date) => void;
}
interface timeProps {
  id: number;
  time: string;
  selected: boolean;
}

export const ScheduleTimes = ({ loading, times, onSelect }: ScheduleTimesProps): JSX.Element => {
  const [listTime, setListTime] = useState<timeProps[] | []>([]);
  useEffect(() => {
    if (times) {
      setListTime(
        times?.availableTimes?.map((item: string, idx: number) => {
          return { id: idx, time: item, selected: false };
        }),
      );
    }
  }, [times]);

  const formatSelectDate = (time: string) => {
    if (times) {
      const dateTimeString = `${times.date} ${time}`;
      const parsedDate = parse(dateTimeString, 'dd/MM/yyyy HH:mm', new Date(), { locale: ptBR });
      return parsedDate;
    }
  };

  const handleSelected = (id: number, datItem: timeProps[], item: timeProps) => {
    onSelect(formatSelectDate(item.time));
    setListTime(
      datItem.map((item: timeProps) => {
        return { id: item.id, time: item.time, selected: item.id === id };
      }),
    );
  };

  return (
    <>
      <S.TimetableWrapper>
        {loading ? (
          <S.WrapperLoading>
            <S.Loading />
          </S.WrapperLoading>
        ) : listTime?.length > 0 ? (
          listTime?.map((item: timeProps) => (
            <S.WrapperPeriod
              key={item.id}
              selected={item.selected}
              onPress={() => {
                handleSelected(item.id, listTime, item);
              }}>
              <S.WrapperHour selected={item.selected}>
                <Typography variant="body2" color={item.selected ? 'white' : 'black'}>
                  {item.time}
                </Typography>
              </S.WrapperHour>
            </S.WrapperPeriod>
          ))
        ) : (
          <Typography variant="paragraph" color="black">
            Sem horários para esta data
          </Typography>
        )}
      </S.TimetableWrapper>
    </>
  );
};
