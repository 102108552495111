import { useDeepLinkStore } from './deep-link.store';
import { CompleteDataSSOGatekeeper } from '~/auth/gatekeepers/complete-data-sso-gatekeeper';
import { IGatekeeper } from '~/auth/interfaces/gatekeeper';
import { BaseStrategy } from '~/auth/strategies/base-strategy';
import { OlimpoService } from '~/services/api/olimpo/olimpo-service-class';
import { useAuthStore } from '~/store/auth.store';
import { IDeepLinkAuthenticateOptions } from '~/auth/strategies/deep-link/interfaces/deep-link-authenticate-options';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { navigationRef } from '~/router/navigator';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

export class DeepLinkStrategy extends BaseStrategy {
  constructor() {
    super();
  }

  static StrategyName = EAuthStrategy.DeepLink;

  getGateKeepers(): IGatekeeper[] {
    return [new CompleteDataSSOGatekeeper()];
  }

  async authenticate(options: IDeepLinkAuthenticateOptions): Promise<void> {
    const { code, slug } = options;

    const deepLinkParams = await OlimpoService.deepLink.validateDeepLink(code);

    useDeepLinkStore.setState({
      deepLinkCode: code,
      feature: deepLinkParams.feature,
      isDeepLink: true,
      showFooter: deepLinkParams.showFooter,
      showHeader: deepLinkParams.showHeader,
      showSummary: deepLinkParams.showSummary,
      claimNumber: deepLinkParams.claimNumber
    });

    useAuthStore.setState({
      token: deepLinkParams.tokenZeus,
      refreshToken: deepLinkParams.refreshToken,
    });

    await this.updateMyProducts();

    const product = useAuthStore.getState().userProducts?.find((item) => {
      return item?.slug === slug;
    });

    await this.updateProfileData();
    await this.selectProduct(product!.onixCode);
  }

  override getInitialParams() {
    const feature = useDeepLinkStore.getState().feature;
    const settings = DeepLink.getSettings(feature);
    return settings.params;
  }

  override async onLogout(): Promise<void> {
    useDeepLinkStore.setState({
      deepLinkCode: '',
      feature: 'consulta-presencial',
      isDeepLink: false,
      showFooter: true,
      showHeader: true,
      showSummary: true,
    });

    await super.onLogout();
  }

  override async onTokenExpire(): Promise<void> {
    await super.onTokenExpire();
    navigationRef.navigate('DeepLinkLoggedOut');
  }

  override mountAuthorizedRoutes() {
    const feature = useDeepLinkStore.getState().feature;
    return DeepLink.getSettings(feature).allowedRoutes;
  }
}
