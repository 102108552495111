import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import * as S from './styles';
import { Typography } from '~/components/@tem-ui';

interface SelectDateProps {
  onNext: () => void;
  onPrev: () => void;
  availableDates: Date[];
  currentDate: number;
}

export const SelectDate = ({
  onNext,
  onPrev,
  availableDates,
  currentDate,
}: SelectDateProps): JSX.Element => {
  const theme = useTheme();
  const [previousDate, setPreviousDate] = useState<boolean>(false);
  const [nextDate, setNextDate] = useState<boolean>(false);

  const handleNextDate = () => {
    onNext();
  };

  const handlePrevDate = () => {
    onPrev();
  };

  useEffect(() => {
    setPreviousDate(!!availableDates?.[currentDate - 1]);
    setNextDate(!!availableDates?.[currentDate + 1]);
  }, [currentDate, availableDates]);

  return (
    <S.WrapperSelect>
      <S.WrapperClick disabled={!previousDate} onPress={handlePrevDate}>
        <Icon
          name="chevron-left"
          size={26}
          color={previousDate ? theme?.colors?.primary : theme?.colors?.gray20}
        />
      </S.WrapperClick>
      <Typography variant="heading3" color="black">
        {availableDates?.[currentDate] &&
          format(new Date(availableDates?.[currentDate]), 'EEEE, dd MMMM', {
            locale: ptBR,
          })}
      </Typography>
      <S.WrapperClick onPress={handleNextDate} disabled={!nextDate}>
        <Icon
          name="chevron-right"
          size={26}
          color={nextDate ? theme?.colors?.primary : theme?.colors?.gray20}
        />
      </S.WrapperClick>
    </S.WrapperSelect>
  );
};
