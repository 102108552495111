import { Route } from '@react-navigation/native';
import { AxiosResponse } from 'axios';

export enum trackingEventType {
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Downloaded = 'DOWNLOADED',
  FirstAccess = 'FIRST_ACCESS',
  PasswordChanged = 'PASSWORD_CHANGED',
  ForgotPassword = 'FORGOT_PASSWORD',
  AddedDependent = 'ADDED_DEPENDENT',
  DependentDeleted = 'DEPENDENT_DELETED',
  UpdatedDependent = 'UPDATED_DEPENDENT',
  JourneyAbandonment = 'JOURNEY_ABANDONMENT',
  SameScreenMoreThan1Minute = 'SAME_SCREEN_MORE_THAN_1_MINUTE',
  BalanceAddedOnCard = 'BALANCE_ADDED_ON_CARD',
  ConsultedTheExtract = 'CONSULTED_THE_EXTRACT',
  ClickedOnRecharge = 'CLICKED_ON_RECHARGE',
  ClickedOnMyBenefits = 'CLICKED_ON_MY_BENEFITS',
  ClickedOnRegulation = 'CLICKED_ON_REGULATION',
  ClickedOnPrivacyPolicy = 'CLICKED_ON_PRIVACY_POLICY',
  ClickedOnAcceptedThePrivacyPolicy = 'CLICKED_ON_ACCEPTED_THE_PRIVACY_POLICY',
  ClickedOnMyAppointments = 'CLICKED_ON_MY_APPOINTMENTS',
  ClickedOnFamilyProgram = 'CLICKED_ON_FAMILY_PROGRAM',
  ClickedOnMyProfile = 'CLICKED_ON_MY_PROFILE',
  ClickedOnMyCards = 'CLICKED_ON_MY_CARDS',
  ClickedOnMyHealthCard = 'CLICKED_ON_MY_HEALTH_CARD',
  UpdatedMyProfile = 'UPDATED_MY_PROFILE',
  ErrorUpdatingMyProfile = 'ERROR_UPDATING_MY_PROFILE',
  ClickedOnDiscountClub = 'CLICKED_ON_DISCOUNT_CLUB',
  ClickedOnfuneralAssistance = 'CLICKED_ON_FUNERAL_ASSISTANCE',
  ClickedOnHealthGuidance24h = 'CLICKED_ON_HEALTH_GUIDANCE_24H',
  ClickedOnRequestedAppointment = 'CLICKED_ON_REQUESTED_APPOINTMENT',
  ClickedOnHealthNetwork = 'CLICKED_ON_HEALTH_NETWORK',
  ClickedOnDiscountOnMedicines = 'CLICKED_ON_DISCOUNT_ON_MEDICINES',
  ClickedOnPresentialAppointment = 'CLICKED_ON_PRESENTIAL_APPOINTMENT',
  RequestedInPresentialAppointment = 'REQUESTED_IN_PRESENTIAL_APPOINTMENT',
  ClickedOnExamAppointment = 'CLICKED_ON_EXAM_APPOINTMENT',
  RequestedInExamAppointment = 'REQUESTED_IN_EXAM_APPOINTMENT',
  ClickedOnVideoAppointment = 'CLICKED_ON_VIDEO_APPOINTMENT',
  RequestedInVideoAppointment = 'REQUESTED_IN_VIDEO_APPOINTMENT',
  ClickedOnPsychologicalVideoTherapy = 'CLICKED_ON_PSYCHOLOGICAL_VIDEO_THERAPY',
  RequestedInPsychologicalVideoTherapy = 'REQUESTED_IN_PSYCHOLOGICAL_VIDEO_THERAPY',
  ClickedOnExamsAndProcedures = 'CLICKED_ON_EXAMS_AND_PROCEDURES',
  RequestedInExamsAndProcedures = 'REQUESTED_IN_EXAMS_AND_PROCEDURES',
  ClickedOnMedicalConsultationaAndTherapy = 'CLICKED_ON_MEDICAL_CONSULTATION_AND_THERAPY_HEALTH_NETWORK',
  RequestedInMedicalConsultationaAndTherapy = 'REQUESTED_IN_MEDICAL_CONSULTATION_AND_THERAPY_HEALTH_NETWORK',
  ClickedOnExamsAndProceduresWithoutMedicalOrder = 'CLICKED_ON_EXAMS_AND_PROCEDURES_WITHOUT_MEDICAL_ORDER_HEALTH_NETWORK',
  RequestedInExamsAndProceduresWithoutMedicalOrder = 'REQUESTED_IN_EXAMS_AND_PROCEDURES_WITHOUT_MEDICAL_ORDER_HEALTH_NETWORK',
  ClickedOnExamsAndProceduresForVideoWithoutDoctorRequest = 'CLICKED_ON_EXAMS_AND_PROCEDURES_WITHOUT_MEDICAL_ORDER_HEALTH_NETWORK',
  RequestedInExamsAndProceduresForVideoWithoutDoctorRequest = 'REQUESTED_IN_EXAMS_AND_PROCEDURES_WITHOUT_MEDICAL_ORDER_HEALTH_NETWORK',
  ClickedOnDentalConsultationsAndProcedures = 'CLICKED_ON_DENTAL_CONSULTATIONS_AND_PROCEDURES_HEALTH_NETWORK',
  RequestedInDentalConsultationsAndProcedures = 'REQUESTED_IN_DENTAL_CONSULTATIONS_AND_PROCEDURES_HEALTH_NETWORK',
  ClickedOnSchedulePresencialConsultationWithoutMedicalRequest = 'CLICKED_ON_SCHEDULE_PRESENCIAL_CONSULTATION_WITHOUT_MEDICAL_REQUEST',
  ClickedOnScheduleVideoConsultationWithoutMedicalRequest = 'CLICKED_ON_SCHEDULE_VIDEO_CONSULTATION_WITHOUT_MEDICAL_REQUEST',
  ClickedOnOpenWhatsApp = 'CLICKED_ON_OPEN_WHATSAPP',
  ClickedOnOpenPhoneNumber = 'CLICKED_ON_OPEN_PHONE_NUMBER',
  ClickedOnOpenWhatsAppMyClinic = 'CLICKED_ON_OPEN_WHATSAPP_MY_CLINIC',
  ClickedOnOpenPhoneNumberMyClinic = 'CLICKED_ON_OPEN_PHONE_NUMBER_MY_CLINIC',
  ClickedOnScheduleSpecialty = 'CLICKED_ON_SCHEDULE_SPECIALTY',
  ClickedOnSelectDoctorTelemedicine = 'CLICKED_ON_SELECT_DOCTOR_TELEMEDICINE',
  RequestedTelemedicineConecta = 'REQUESTED_TELEMEDICINE_CONECTA',
  ClickedOn24hVideoConsultation = 'CLICKED_ON_24H_VIDEO_CONSULTATION',
  ClickedOn24HVideoClickPatient = 'CLICKED_ON_24H_VIDEO_CLICK_PATIENT',
  ClickedOn24HVideoClickStart = 'CLICKED_ON_24H_VIDEO_CLICK_START',
  Started24HVideo = 'STARTED_24H_VIDEO',
  ClickedOnPatient = 'CLICKED_ON_PATIENT',
  ClickedOnScheduleDateAndTime = 'CLICKED_ON_SCHEDULE_DATE_AND_TIME',
  RequestedInSurgicalProcedure = 'REQUESTED_IN_SURGICAL_PROCEDURE',
  RequestedInTelemedicineAppointment = 'REQUESTED_IN_TELEMEDICINE_APPOINTMENT',
}

export interface trackingPayloadType {
  event: trackingEventType | string;
  userId?: number;
  onix?: string | null;
  origin?: string;
  metadata?: object | null;
}

export interface contextTrackingTypes {
  addMovement: (payload: trackingPayloadType) => Promise<AxiosResponse<any>>;
  startTimerTracker: (_screen: Route<string, object | undefined> | undefined) => void;
}
