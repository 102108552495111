import React, { useState, useCallback, useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import * as S from '../styles';
import { CalendarInput } from './CalendarInput';
import { SelectDate } from './SelectDate/Index';
import { ScheduleTimes } from './ScheduleTimes';
import { Spacing } from '~/components/Spacing';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { useApi } from '~/hooks/api';
import { Button, useMedia, Typography, Dialog, MobileAutoSpace } from '~/components/@hello-ui';
import { DoctorSchedule } from '~/contexts/types';
import { parse } from 'date-fns';
import format from 'date-fns/format';
import { useDialog, Wrapper } from '~/components/@tem-ui';

export const TelemedicineScheduleDate = (): JSX.Element => {
  const api = useApi();
  const theme = useTheme();
  const media = useMedia();

  const { nextStep, previousStep } = useStepper();

  const { appointment, setAppointment } = useAppointment();
  const { openDialog } = useDialog();

  const [currentDate, setCurrentDate] = useState<DoctorSchedule | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | string | undefined>();
  const [dataTimes, setDataTimes] = useState<DoctorSchedule[] | undefined>();
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const getDateIndex = (date: string) => {
    return dataTimes?.findIndex((item) => item.date === date);
  };

  const handleNextDate = () => {
    const index = getDateIndex(currentDate?.date);
    setCurrentDate(dataTimes?.[index + 1]);
    setSelectedDate(undefined);
  };

  const handlePrevDate = () => {
    const index = getDateIndex(currentDate?.date);
    setCurrentDate(dataTimes?.[index - 1]);
    setSelectedDate(undefined);
  };

  function formatDateString(dateString: string) {
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day, 12);
  }

  const handleGetDate = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.doctorSchedules(appointment?.doctorId);

      if (!data || data?.length === 0) {
        openDialog(
          'Médico sem horários disponiveis no momento',
          <Wrapper style={{ margin: 0 }}>
            <Typography variant="body1">selecione outro profissional</Typography>
          </Wrapper>,
        );
        previousStep();
        setLoading(false);
        return;
      }

      setDataTimes(data.data);
      setCurrentDate(data.data[0]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      openDialog(
        'Ops, algo deu errado',
        <Wrapper style={{ margin: 0 }}>
          <Typography variant="body1">Tente novamente mais tarde.</Typography>
        </Wrapper>,
      );
      previousStep();
      console.log(error);
    }
  }, []);

  useEffect(() => {
    void handleGetDate();
  }, []);

  return (
    <>
      <S.WrapperSchedule>
        <Spacing bottom={16}>
          <Typography variant="title" color="black">
            Selecione a data e o horário
          </Typography>
        </Spacing>
        <S.WrapperDays>
          <SelectDate
            onNext={handleNextDate}
            onPrev={handlePrevDate}
            currentDate={getDateIndex(currentDate?.date)}
            availableDates={(dataTimes || [])?.map(({ date }) => formatDateString(date))}
          />
          <S.WrapperLinkCalendar
            onPress={() => {
              setDialogIsOpen(true);
            }}>
            <Icon name="calendar" size={26} color={theme?.colors?.black} />
            <Typography variant="link" color="black" style={{ marginLeft: 10, paddingTop: 2 }}>
              Visualizar mais datas
            </Typography>
            <Icon
              name="chevron-right"
              style={{ marginLeft: 2, marginTop: 5 }}
              size={22}
              color={theme?.colors?.primary}
            />
          </S.WrapperLinkCalendar>
          <Spacing top={media.isMobile ? 16 : 26}>
            <Typography variant="body2" color="black" style={{ height: 30 }}>
              Horários disponíveis
            </Typography>
          </Spacing>
          <Spacing top={2}>
            <ScheduleTimes
              loading={loading}
              times={currentDate}
              onSelect={(date) => {
                setSelectedDate(date);
              }}
            />
          </Spacing>
        </S.WrapperDays>
      </S.WrapperSchedule>
      <MobileAutoSpace heightDesktop={24} />
      <Button
        variant={'primary'}
        disabled={!selectedDate}
        onPress={() => {
          setAppointment({ ...appointment, appointmentDate: selectedDate });
          nextStep();
        }}>
        Próximo
      </Button>

      <Dialog
        visible={dialogIsOpen}
        onClose={() => {
          setDialogIsOpen(false);
        }}>
        <CalendarInput
          markedDates={dataTimes}
          onSelect={(day: any) => {
            const parsedDay = parse(day.dateString, 'yyyy-MM-dd', new Date());
            const formatted = format(parsedDay, 'dd/MM/yyyy');
            const index = getDateIndex(formatted);
            if (dataTimes?.[index]) {
              setCurrentDate(dataTimes[index]);
              setDialogIsOpen(false);
            }
          }}
        />
      </Dialog>
    </>
  );
};
