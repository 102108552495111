import React, { useEffect } from 'react';
import { NavigationProp, useNavigation, useRoute } from '@react-navigation/core';
import { View } from 'react-native';
import { ScheduleAllTimeSteps } from '~/screens';

import VideoCall24Icon from '~/components/Icons/VideoCall24Icon';

import { Stepper } from '~/components/@hello-ui/Stepper/Stepper';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';
import { useAppointment } from '~/hooks/appointment';
import { useAuth } from '~/auth/legacy/useAuth';
import { trackingEventType } from '~/contexts/tracking/types';

import { UseRoute } from '~/@types/navigation/routes-helpers';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { SelectDependents } from '~/screens/ScheduleAppointment/components/SelectDependents/SelectDependents';
import { Dentistry24HoursIcon } from '~/components/Icons/Dentistry24Hours';

export const Schedule24h = (): JSX.Element => {
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();
  const { params } = useRoute<UseRoute<'Schedule24h'>>();
  const {
    scrollDisabled,
    customPadding,
    currentStep,
    stepper,
    finalizeSteps,
    setCurrentStepState,
    toStepper,
  } = useStepper();

  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { clearAppointments } = useAppointment();
  const { checkIsEnabledFeature } = useAuth();
  const { isDeepLink } = DeepLink.useParams();

  useEffect(() => {
    clearAppointments();
  }, []);

  const showCancelMessage = () => {
    showSweetAlert(
      'Cancelar solicitação',
      'Tem certeza que deseja sair da solicitação de agendamento?',
      'info',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => {
              hideSweetAlert();
            },
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: () => {
              finalizeSteps();
              setCurrentStepState(0);
              clearAppointments();

              if (params?.toStepper !== undefined && isDeepLink) {
                toStepper(params?.toStepper);
              }

              if (params?.toStepper !== undefined && params?.fromPage) {
                navigation.navigate(params?.fromPage);
              }

              showSweetAlert(
                'Tudo certo!',
                'Sua solicitação foi cancelada',
                'success',
                false,
                false,
                {
                  layout: 'helloUi',
                  buttons: [
                    {
                      variant: 'primary',
                      text: 'Ok',
                      onPress: () => {
                        hideSweetAlert();
                      },
                    },
                  ],
                },
              );
            },
          },
        ],
      },
    );
  };

  const card = [
    {
      icon: <VideoCall24Icon />,
      title: 'Pronto Atendimento 24h por Vídeo',
      customTitle: 'Pronto Atendimento por Vídeo',
      description: 'Telemedicina com clínico geral',
      disabled:
        !checkIsEnabledFeature('telemedicine_24') &&
        !checkIsEnabledFeature('medex_telemedina24') &&
        !checkIsEnabledFeature('Brasil_Telemedicina24'),
      visible:
        checkIsEnabledFeature('telemedicine_24') ||
        checkIsEnabledFeature('medex_telemedina24') ||
        checkIsEnabledFeature('Brasil_Telemedicina24'),
      visibilitySteps: false,
      steps: [
        <SelectDependents key="ScheduleAllTime" />,
        <ScheduleAllTimeSteps key="ScheduleAllTimeSteps" />,
      ],
      tracking: trackingEventType.ClickedOn24hVideoConsultation,
      iconSideOfTitle: true,
    },
    {
      icon: <Dentistry24HoursIcon color="primary" />,
      title: 'Assistência de Urgência Odontológica',
      description: 'Para cuidados odontológicos emergenciais',
      visible: checkIsEnabledFeature('dentistry24hrs'),
      onPress: () => navigation.navigate('BenefitsDentistry24Hours'),
      visibilitySteps: false,
      tracking: trackingEventType.ClickedOn24hVideoConsultation,
      iconSideOfTitle: true,
    },
  ];

  useEffect(() => {
    if (params?.toStepper !== undefined) {
      toStepper(params.toStepper);
    }
  }, [params?.toStepper, toStepper]);

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Consulta por Vídeo 24 horas',
        onBack:
          currentStep === 0 && isDeepLink
            ? undefined
            : () => {
                if (currentStep >= 0 && stepper !== null) {
                  if (params?.toStepper && !isDeepLink) {
                    navigation.navigate('Dashboard');
                    return;
                  }

                  showCancelMessage();
                } else {
                  finalizeSteps();
                  clearAppointments();
                  navigation.navigate('Dashboard');
                }
              },
      }}
      overrideScrollEnabled={!scrollDisabled}
      overridePadding={customPadding}>
      <View className="min-h-full">
        <Stepper items={card} validateDependentFeatures />
      </View>
    </PageWithCard>
  );
};
