import { persist, createJSONStorage } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { create } from 'zustand';
import { IDeepLinkStore } from '~/auth/strategies/deep-link/interfaces/deep-link-store';
import { useGlobalStore } from '~/store/global.store';

export const useDeepLinkStore = create(
  persist<IDeepLinkStore>(
    () => ({
      isDeepLink: false,
      feature: 'consulta-presencial',
      showHeader: false,
      showFooter: false,
      showSummary: false,
      deepLinkCode: '',
      claimNumber: ''
    }),
    {
      name: '@tem::deep-link',
      storage: createJSONStorage(() => AsyncStorage),
      onRehydrateStorage: () => {
        useGlobalStore.getState().addLoadingStore('deep-link');

        return (state, error) => {
          if (!error && state) {
            useGlobalStore.getState().loadStore('deep-link');
          }
        };
      },
    },
  ),
);
