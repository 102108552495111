import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import FeatherIcon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { Typography, useSweetAlert } from '~/components/@hello-ui';
import CardIcon from '~/components/Icons/CardIcon';
import BarCodeIcon from '~/components/Icons/BarCodeIcon';
import { RechargeValue } from '~/screens/TemPayRecharge/components/RechargeValue';
import { useRecharge } from '~/components/@tem-ui/Recharge';
import { useAuth } from '~/auth/legacy/useAuth';
import PixIcon from '~/components/Icons/PixIcon';
import { useTemPay } from '~/hooks/temPay';

type addressType = {
  address: string;
  state: string;
  city: string;
  postal_code: string;
};

export const PaymentOptions = () => {
  const { setRechargeStep } = useRecharge();
  const theme = useTheme();
  const { card } = useTemPay();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { user, partner } = useAuth();
  const navigation = useNavigation();

  const addressValidate = (address: addressType) => {
    const addressData =
      address.address.length === 0 ||
      address.state.length === 0 ||
      address.city.length === 0 ||
      address.postal_code.length === 0;
    return addressData;
  };

  const handlePressOption = (option: 'credit-card' | 'billet' | 'pix') => {
    return () => {
      if (addressValidate(user?.address)) {
        showSweetAlert(
          'Ops, precisamos do seu endereço ',
          'É necessário ter seu endereço cadastrado para fazer recargas.',
          'warning',
          false,
          false,
          {
            layout: 'helloUi',
            buttons: [
              { text: 'Cancelar', variant: 'outline', onPress: () => hideSweetAlert() },
              {
                text: 'Adicionar',
                variant: 'primary',
                onPress: () => {
                  navigation.navigate('UpdateProfileAddress', { returnPage: 'TemPayRecharge' });
                  hideSweetAlert();
                },
              },
            ],
          },
        );
        return;
      }

      if (!user?.email) {
        showSweetAlert(
          'Ops, precisamos do seu e-mail ',
          'É necessário ter o e-mail cadastrado para fazer recargas.',
          'warning',
          false,
          false,
          {
            layout: 'helloUi',
            buttons: [
              { text: 'Cancelar', variant: 'outline', onPress: () => hideSweetAlert() },
              {
                text: 'Adicionar',
                variant: 'primary',
                onPress: () => {
                  navigation.navigate('UpdateProfileData', { returnPage: 'TemPayRecharge' });
                  hideSweetAlert();
                },
              },
            ],
          },
        );
        return;
      }

      switch (option) {
        case 'credit-card':
          setRechargeStep(2);
          break;
        case 'billet':
          setRechargeStep(3);
          break;
        case 'pix':
          setRechargeStep(6);
          break;
      }
    };
  };

  return (
    <>
      <RechargeValue className="mb-40" />
      <Typography variant="title" className="mb-40 text-center mobile:mb-24">
        Como você prefere pagar?
      </Typography>
      <View className="h-[1px] w-full bg-background-gray" />
      <TouchableOpacity
        className="w-full flex-row items-center py-24"
        onPress={handlePressOption('credit-card')}>
        <View className="mr-16 mobile:mr-8">
          <CardIcon />
        </View>
        <Typography variant="body2">Cartão de Crédito</Typography>
        <View className="absolute right-0 top-24">
          <FeatherIcon name="chevron-right" size={24} color={theme.colors.primary} />
        </View>
      </TouchableOpacity>
      <View className="h-[1px] w-full bg-background-gray" />
      {partner?.onix_code !== 7410 && (
        <>
          <TouchableOpacity
            className="w-full flex-row items-center py-24"
            onPress={handlePressOption('billet')}>
            <View className="mr-16 mobile:mr-8">
              <BarCodeIcon />
            </View>
            <Typography variant="body2">Boleto</Typography>
            <View className="absolute right-0 top-24">
              <FeatherIcon name="chevron-right" size={24} color={theme.colors.primary} />
            </View>
          </TouchableOpacity>
          <View className="h-[1px] w-full bg-background-gray" />
        </>
      )}
      <TouchableOpacity
        className="w-full flex-row items-center py-24"
        onPress={handlePressOption('pix')}>
        <View className="mr-16 mobile:mr-8">
          <PixIcon />
        </View>
        <Typography variant="body2">Pix</Typography>
        <View className="absolute right-0 top-24">
          <FeatherIcon name="chevron-right" size={24} color={theme.colors.primary} />
        </View>
      </TouchableOpacity>
    </>
  );
};
