import React, { useEffect, useState } from 'react';
import { Calendar, LocaleConfig, CalendarProps } from 'react-native-calendars';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';

import './CalendarConfig';
import { DoctorSchedule } from '~/contexts/types';
import { format, parse } from 'date-fns';

LocaleConfig.defaultLocale = 'br';

interface CalendarInputProps {
  onSelect: (day: CalendarProps | string) => void;
  markedDates: DoctorSchedule[];
}

export const CalendarInput = ({ onSelect, markedDates }: CalendarInputProps): JSX.Element => {
  const [marked, setMarked] = useState<any>({});

  useEffect(() => {
    var dates = {};
    markedDates?.map(({ date }) => {
      const parsedDate = parse(date, 'dd/MM/yyyy', new Date());
      const formattedDate = format(parsedDate, 'yyyy-MM-dd');
      dates = { ...dates, [formattedDate]: { marked: true, selectedColor: 'blue' } };
    });

    setMarked(dates);
  }, [markedDates]);
  const theme = useTheme();
  return (
    <Calendar
      theme={{
        agendaDayNumColor: theme.colors.gray70,
        todayBackgroundColor: theme.colors.primary,
        todayTextColor: theme.colors.white,
        textDayFontFamily: 'Poppins',
        textDayHeaderFontFamily: 'Poppins',
      }}
      markedDates={marked}
      renderArrow={(direction: string) =>
        direction === 'left' ? (
          <Icon name="chevron-left" size={18} color={theme?.colors?.primary} />
        ) : (
          <Icon name="chevron-right" size={18} color={theme?.colors?.primary} />
        )
      }
      style={{
        borderRadius: 10,
        height: '100%',
        width: '100%',
        padding: 10,
      }}
      current={Date()}
      onDayPress={(day) => {
        onSelect(day);
      }}
    />
  );
};
