import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components/native';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useNavigation, useRoute } from '@react-navigation/native';
import * as S from '../styles';
import { Button, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { useStepper, usePageWithCardSettings } from '~/components/@tem-ui/Stepper';
import { Divider } from '~/components/@hello-ui/Divider';
import { scheduleTypes, TypesEnum } from '~/enums/appointment';
import { useAppointment } from '~/hooks/appointment';
import { useAuth } from '~/auth/legacy/useAuth';
import { useSweetAlert } from '~/components/@tem-ui';
import { capitalize, toCamelCase } from '~/utils/strings';
import { useUploader } from '~/components/@tem-ui/Uploader';
import { UseRoute } from '~/@types/navigation/routes-helpers';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { AppointmentTypes, PatientType } from '~/contexts/types';

interface ScheduleReviewDetailProps {
  title: string;
  description?: string | JSX.Element;
  divider?: boolean;
  noPadding?: boolean;
}

interface ScheduleReviewProps {
  scheduleType: TypesEnum;
  trackingType: string;
  specialtyName?: string;
  customSubmit?: (appointment: AppointmentTypes, pattient: PatientType) => Promise<void>;
}

interface RealTypeProps {
  title: string;
  type: TypesEnum;
}

export const ScheduleReview = ({
  scheduleType,
  trackingType,
  specialtyName,
  customSubmit,
}: ScheduleReviewProps): JSX.Element => {
  usePageWithCardSettings({ scrollEnabled: true });

  const { params } = useRoute<UseRoute<'ScheduleAppointment'>>();
  const { user, isDependent } = useAuth();
  const { finalizeSteps, toStepper, setCurrentStepState, nextStep } = useStepper();
  const {
    setAppointment,
    appointment,
    scheduleSubmissionStatus,
    appointmentLoading,
    submitAppointment,
    clearAppointments,
    patient,
    setPatient
  } = useAppointment();
  const { clearUploader } = useUploader();

  const { isDeepLink, showSummary } = DeepLink.useParams();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const navigation = useNavigation();

  const [realType, setRealType] = useState<RealTypeProps | null>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (isDependent) {
      setPatient(prevPatient => ({...prevPatient, isTitular: false}))
    }
    setRealType(getRealType());
  }, []);

  useEffect(() => {
    if (realType && (appointment || realType.title === 'Checkup')) {
      setAppointment({ ...appointment, type: realType.type, scheduleType });
    }
  }, [realType]);

  useEffect(() => {
    if (scheduleSubmissionStatus) {
      if (isDeepLink ? showSummary : true) {
        scheduleSubmissionStatus && navigation.navigate('ScheduleSuccess');
      } else {
        DeepLink.sendEvent(DeepLinkEvent.Success);
      }
    }
  }, [scheduleSubmissionStatus]);

  const cancelRequest = () => {
    if (!hasSubmitted) {
      showSweetAlert(
        'Cancelar solicitação',
        'Tem certeza que deseja sair da solicitação de agendamento?',
        'info',
        false,
        false,
        {
          layout: 'helloUi',
          buttons: [
            {
              text: 'Não',
              variant: 'outline',
              onPress: () => {
                hideSweetAlert();
              },
            },
            {
              text: 'Sim',
              variant: 'primary',
              onPress: () => {
                clearUploader();
                finalizeSteps();
                setCurrentStepState(0);
                clearAppointments();

                if (params?.toStepper !== undefined && isDeepLink) {
                  DeepLink.sendEvent(DeepLinkEvent.Cancel);
                  toStepper(params.toStepper);
                }

                showSweetAlert(
                  'Tudo certo!',
                  'Sua solicitação foi cancelada',
                  'success',
                  false,
                  false,
                  {
                    layout: 'helloUi',
                    buttons: [
                      {
                        variant: 'primary',
                        text: 'Ok',
                        onPress: () => {
                          hideSweetAlert();
                        },
                      },
                    ],
                  },
                );
              },
            },
          ],
        },
      );
    }
  };

  let address = appointment?.clinic
    ? toCamelCase(`
    Unidade ${appointment.clinic.name} - ${appointment.clinic.address.street}, n° ${appointment.clinic.address.number}, ${appointment.clinic.address.neighbourhood}, ${appointment.clinic.address.zipCode}
  `).trim()
    : '';

  if (address === '' && appointment?.address) {
    address = `${appointment?.address?.neighborhood}, ${toCamelCase(
      appointment?.address?.city ?? '',
    )} - ${toCamelCase(appointment?.address?.state ?? '')}`;
  }

  const getRealType = () => {
    return translateType(scheduleType);
  };

  const translateType = (type: TypesEnum | undefined) => {
    if (type === undefined) {
      return {
        title: '-',
        type: TypesEnum.Appointment,
      };
    }
    return {
      title: scheduleTypes[type] || '-',
      type: type === TypesEnum.Dentistry ? TypesEnum.Appointment : type,
    };
  };

  return (
    <S.FullContainer>
      <S.TitleWrapper>
        <Typography variant="title">Revise sua solicitação</Typography>
      </S.TitleWrapper>
      <S.BodyWrapper>
        <Typography variant="body2">Confirme os dados:</Typography>
      </S.BodyWrapper>
      <S.ReviewDetails>
        <ScheduleReviewDetail
          title="Tipo"
          description={realType?.title === 'Checkup' ? 'Check-Up Médico' : realType?.title}
          divider
        />
        {appointment?.specialty?.name ? (
          scheduleType === TypesEnum.Appointment ? (
            <ScheduleReviewDetail
              title="Especialidade"
              description={
                appointment?.specialty?.name ? toCamelCase(appointment.specialty.name) : '-'
              }
              divider
            />
          ) : (
            <ScheduleReviewDetail
              title="Especialidade"
              description={
                appointment?.specialty?.name ? toCamelCase(appointment.specialty.name) : '-'
              }
              divider
            />
          )
        ) : (
          <></>
        )}
        {appointment?.doctorName ? (
          <ScheduleReviewDetail title="Médico" description={appointment?.doctorName} divider />
        ) : (
          <></>
        )}
        {address ? <ScheduleReviewDetail title="Local" description={address} divider /> : <></>}
        {appointment?.type !== TypesEnum.CheckUp && (
          <ScheduleReviewDetail
            divider
            title="Data e horários"
            description={
              <>
                {appointment?.appointmentDate
                  ? format(appointment?.appointmentDate, 'PPPP HH:mm', { locale: ptBR })
                  : Object.keys(appointment?.days ?? {}).map((day, index) =>
                      capitalize(
                        `${index !== 0 ? '\n' : ''}${format(parseISO(day), 'PPPP', {
                          locale: ptBR,
                        })}: ${appointment?.days?.[day].hours.join('; ')}`,
                      ),
                    )}
              </>
            }
          />
        )}

        <ScheduleReviewDetail
          title="Paciente"
          description={`${toCamelCase(patient?.name ?? user?.name ?? '')} - ${
            !patient || (patient && patient.isTitular) ? 'Titular' : 'Dependente'
          }`}
        />
      </S.ReviewDetails>
      <MobileAutoSpace heightDesktop={40} heightMobile={24} />
      <Button
        variant="primary"
        loading={appointmentLoading}
        disabled={appointmentLoading}
        onPress={async () => {
          if (!hasSubmitted) {
            try {
              if (customSubmit) {
                try {
                  await customSubmit(appointment, patient || user);
                  nextStep();
                } catch (error) {
                  clearUploader();
                  finalizeSteps();
                  setCurrentStepState(0);
                  clearAppointments();
                }
              } else {
                await submitAppointment(trackingType, specialtyName, true);
              }
              setHasSubmitted(true);
            } catch (err) {
              setHasSubmitted(false);
            }
          }
        }}>
        Confirmar
      </Button>
      <S.CancelButton variant="link" onPress={cancelRequest}>
        Cancelar solicitação
      </S.CancelButton>
    </S.FullContainer>
  );
};

export const ScheduleReviewDetail = ({
  title,
  description,
  divider,
  noPadding,
}: ScheduleReviewDetailProps) => {
  const theme = useTheme();

  return (
    <>
      <S.ScheduleWrapperDetail noPadding={noPadding}>
        <Typography variant="body2" color={theme.colors.paragraph}>
          {title}
        </Typography>
        {description && (
          <Typography variant="body2" color={theme.colors.title}>
            {description}
          </Typography>
        )}
      </S.ScheduleWrapperDetail>
      {divider && <Divider />}
    </>
  );
};
