import { lazy } from 'react';

export const Welcome = lazy(() => import('./Welcome').then((c) => ({ default: c.Welcome })));

export const DocumentNumber = lazy(() =>
  import('./DocumentNumber').then((c) => ({ default: c.DocumentNumber })),
);

export const NotFound = lazy(() => import('./NotFound').then((c) => ({ default: c.NotFound })));

export const VerifyCode = lazy(() =>
  import('./VerifyCode/VerifyCode').then((c) => ({ default: c.VerifyCode })),
);

export const CodeError = lazy(() => import('./CodeError').then((c) => ({ default: c.CodeError })));

export const Password = lazy(() => import('./Password').then((c) => ({ default: c.Password })));

export const CreatePassword = lazy(() =>
  import('./CreatePassword/CreatePassword').then((c) => ({ default: c.CreatePassword })),
);

export const NoProducts = lazy(() =>
  import('./NoProducts').then((c) => ({ default: c.NoProducts })),
);

export const Success = lazy(() => import('./Success').then((c) => ({ default: c.Success })));

export const NoPhone = lazy(() => import('./NoPhone').then((c) => ({ default: c.NoPhone })));

export const PhoneNumber = lazy(() =>
  import('./PhoneNumber/PhoneNumber').then((c) => ({ default: c.PhoneNumber })),
);

export const BirthdateVerifier = lazy(() =>
  import('./BirthdateVerifier/BirthdateVerifier').then((c) => ({ default: c.BirthdateVerifier })),
);

export const LastnameVerifier = lazy(() =>
  import('./LastNameVerifier/LastNameVerifier').then((c) => ({ default: c.LastNameVerifier })),
);

export const PendingInformation = lazy(() =>
  import('./ContactSupport/PendingInformation').then((c) => ({ default: c.PendingInformation })),
);
